<template>
  <div class="products-detail-pcb common-page">
    <div class="page-top">
      <h1 class="title">{{ topData.name }}</h1>
      <p class="text">{{ topData.summary }}</p>
      <!-- <div class="btn-wrap">
        <router-link :to="topData.link">
          <div class="btn-text">查看产品详情</div>
        </router-link>
      </div> -->
    </div>
    <div class="common-card-wrap card-wrap">
      <div class="text-box" v-html="topData.detail"></div>
      <div
        v-for="(item, index) in cardList"
        :key="index"
        class="small-card"
        @click="goDetail(item.uuid)"
      >
        <img :src="item.image" alt="" />
        <div class="about">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.sub_title }}</div>
          <div class="btn-wrap">
              <div class="btn-text">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty"></div>
  </div>
</template>
<script>
import request from '../../utils/request';
import dayjs from 'dayjs';
export default {
  name: "ProductsDetailPcb",
  data() {
    return {
      topData: {
        title: "PCB连接器_德国浩亭",
        text: "设备和应用变得越来越小型、越来越紧凑。因此，浩亭为各种尺寸以及需要使用PCB的应用提供解决方案。",
        link: "",
      },
      cardList:[
          {
            imgUrl: require("../../image/products-detail-pcb03.png"),
            title: "浩亭 har-modular®",
            text: "适用于电路板应用的新型模块化连接解决方案。创建自己的产品！",
            link: "/productsDetailHar",
          },
          {
            imgUrl: require("../../image/products-detail-pcb01.png"),
            title: "浩亭 har-flex®",
            text: "充分利用基于 PCB 的浩亭解决方案！",
            link: "",
          },
          {
            imgUrl: require("../../image/products-detail-pcb02.png"),
            title: "DIN 41612 连接器",
            text: "DIN 连接器无数次地证明自身是用于连接电路板和主干网久经考验的经典连接器。浩亭客户对其坚固性以及不断的进步和优化赞不绝口。",
            link: "",
          },
      ]
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY.MM.DD HH:mm');
    },
    // 获取新闻列表数据
    fetchInfo(id) {
      request.get('/product_type/'+id) // 假设接口地址为 /news
        .then(response => {
          this.topData = response; // 将接口返回的数据赋值给newsList
        })
        .catch(error => {
          console.error('Error fetching news:', error);
        });
    },
    fetchList(id) {
      request.get('/product/all_by_type/'+id, {
        page_size: 10,
        page_num: 1
      }) // 假设接口地址为 /news
        .then(response => {
          this.cardList = response.data; // 将接口返回的数据赋值给newsList
        })
        .catch(error => {
          console.error('Error fetching news:', error);
        });
    },
    goDetail(id) {
        this.$router.push({ name: 'productDetail', query: { id: id } })
    },
  },
  created() { 
    const id = this.$route.query.id;
    console.log("🚀 ~ created ~ id:", id)
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchInfo(id);
    this.fetchList(id)
  },
};
</script>
<style lang="less" scoped>
.products-detail-pcb {
  .page-top {
    padding: 32px;
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 33.6px */
    }
    .text {
      margin-bottom: 8px;
      color: #505054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
    }
  }
  .btn-wrap {
    display: flex;
    a {
      display: block;
    }
    .btn-text {
      box-sizing: border-box;
      width: 160px;
      text-align: center;
      padding: 12px 24px;
      border-radius: 40px;
      background: @theme-color;
      color: #212027;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 19.2px */
    }
  }
  .text-box {
    margin-bottom: 32px;
    color: #535359;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .text {
      margin-bottom: 16px;
    }
  }
  .card-wrap {
    .small-card {
      margin-bottom: 32px;
      overflow: hidden;
      border-radius: 24px;
      border: 1px solid #fff;
      background: #fff;
      /* 卡片投影 */
      box-shadow: 0px 0px 16px 0px #d7d9dc;
      img {
        display: block;
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .about {
        padding: 16px 16px 24px 16px;
        .title {
          margin-bottom: 8px;
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 21.6px */
        }
        .text {
          margin-bottom: 16px;
          color: #505054;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
        }
      }
    }
  }
  .empty {
    width: 100%;
    height: 80px;
  }
}
</style>
<style lang="less">
.products-detail-pcb {
  .text-box {
    .text {
      margin-bottom: 16px;
    }
    p{
      display: flex;
    }
    a{
      position: relative;
      // word-wrap: break-word; /* 或者使用 overflow-wrap: break-word; */  
      // white-space: normal; /* 确保空白符被正确处理 */  
      padding: 12px 44px 12px 12px;
      background: #ffcc00;
      font-size: 16px;
      font-weight: 600;
      border-radius:  20px;
      white-space: nowrap; /* 防止内容换行 */  
      overflow: hidden; /* 隐藏溢出的内容 */  
      text-overflow: ellipsis; /* 超出部分显示为省略号 */  
    }
    a:after{
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;
      content: '→';
    }
  }
}
</style>
